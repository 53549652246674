import * as React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
// Customizable Area End

import GoogleContactsImportController, {
  Props,
  configJSON,
  Contacts,
} from "./GoogleContactsImportController.web";
import GoogleLoginButton from "./GoogleLoginButton";

export default class GoogleContactsImport extends GoogleContactsImportController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderContactItem = (
    item: Contacts["connections"][number],
    index: number
  ) => {
    return (
      <TableRow data-test-id="contactsList" key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell data-test-id={"contactName-" + index}>
          {item.names?.length > 0 && item.names[0].displayName}
        </TableCell>
        <TableCell>
          {item.phoneNumbers?.length > 0 && item.phoneNumbers[0].value}
        </TableCell>
      </TableRow>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Container style={webStyle.googleBtnWrapper}>
              <GoogleOAuthProvider clientId={configJSON.clientIDWeb}>
                <GoogleLoginButton
                  data-test-id="googleSignInBtn"
                  isSingedIn={this.state.isSingedIn}
                  onSuccess={this.googleLoginSuccess}
                  onError={this.googleLoginError}
                  onLogout={this.googleSignOut}
                />
              </GoogleOAuthProvider>
            </Container>
            {this.state.authDetails && this.state.contacts && (
              <Container>
                <Container style={webStyle.mainWrapper}>
                  <Typography variant="h6" style={webStyle.contactListTitle}>
                    {configJSON.contactList}
                  </Typography>
                  <Typography variant="h6" style={webStyle.totalContact}>
                    {configJSON.totalContact} {this.state.contacts.totalPeople}
                  </Typography>
                </Container>
                <TableContainer
                  style={webStyle.tableContainer}
                  component={Paper}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={webStyle.tableID}>
                          {configJSON.hashTag}
                        </TableCell>
                        <TableCell>{configJSON.contactName}</TableCell>
                        <TableCell>{configJSON.contactNumber}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.contacts.connections.map(
                        this.renderContactItem
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            )}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    button: {
      textTransform: "none",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30px",
    alignItems: "center",
  },
  totalContact: {
    fontSize: 14,
  },
  tableContainer: {
    marginTop: 25,
  },
  tableID: {
    width: 10,
  },
  googleBtnWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 50,
  },
  contactListTitle: {
    flex: 1,
  },
};
// Customizable Area End
