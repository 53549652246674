Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiUrl = "https://api.dictionaryapi.dev/api/v2/entries/en/"
exports.inputFieldPlaceholder = "Enter a word";
exports.searchBtn = "Search";
exports.pageTitle = "Word Loopup";
exports.emptyFieldMsg = "Please enter a word";
exports.tryAgainMsg = "Please try again with different word!"
exports.getApiMethodType = "GET";
exports.alertTitle = "Alert";
exports.soundErrorMsg = "Error on loading track!"
// Customizable Area End