// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Avatar from "../../blocks/avatar/src/Avatar";
import Print from "../../blocks/print/src/Print";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import WordLookup from "../../blocks/wordlookup/src/WordLookup";
import Analytics from "../../blocks/analytics/src/Analytics";
import Emojis from "../../blocks/emojis/src/Emojis";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Translation from "../../blocks/translation/src/Translation";
import CustomProfileHeader from "../../blocks/customprofileheader/src/CustomProfileHeader";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import PasscodeLock from "../../blocks/passcodelock/src/PasscodeLock";
import Captcha from "../../blocks/captcha/src/Captcha";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Compass from "../../blocks/compass/src/Compass";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import TimeZoneDetection from "../../blocks/timezonedetection/src/TimeZoneDetection";
import Videos from "../../blocks/videos/src/Videos";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import CharacterCount from "../../blocks/charactercount/src/CharacterCount";
import CreateComment from "../../blocks/comments/src/CreateComment";
import TextComparison from "../../blocks/textcomparison/src/TextComparison";
import RandomNumberGenerator from "../../blocks/randomnumbergenerator/src/RandomNumberGenerator";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import InvertColour from "../../blocks/invertcolour/src/InvertColour";
import GoogleNewsIntegration from "../../blocks/googlenewsintegration/src/GoogleNewsIntegration";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import BeeFreeIntegration from "../../blocks/beefreeintegration/src/BeeFreeIntegration";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import WorldClock from "../../blocks/worldclock/src/WorldClock";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import GoogleContactsImport from "../../blocks/googlecontactsimport/src/GoogleContactsImport";
import Geofence from "../../blocks/geofence/src/Geofence";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cobrowsing from "../../blocks/cobrowsing/src/Cobrowsing";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import WhiteboardCollaboration from "../../blocks/whiteboardcollaboration/src/WhiteboardCollaboration";


const routeMap = {
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Avatar:{
 component:Avatar,
path:"/Avatar"},
Print:{
 component:Print,
path:"/Print"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
WordLookup:{
 component:WordLookup,
path:"/WordLookup"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Emojis:{
 component:Emojis,
path:"/Emojis"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PdfEdit:{
 component:PdfEdit,
path:"/PdfEdit"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Translation:{
 component:Translation,
path:"/Translation"},
CustomProfileHeader:{
 component:CustomProfileHeader,
path:"/CustomProfileHeader"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
PasscodeLock:{
 component:PasscodeLock,
path:"/PasscodeLock"},
Captcha:{
 component:Captcha,
path:"/Captcha"},
DesktopNotifications:{
 component:DesktopNotifications,
path:"/DesktopNotifications"},
PasswordStrength:{
 component:PasswordStrength,
path:"/PasswordStrength"},
Compass:{
 component:Compass,
path:"/Compass"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
TimeZoneDetection:{
 component:TimeZoneDetection,
path:"/TimeZoneDetection"},
Videos:{
 component:Videos,
path:"/Videos"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
CharacterCount:{
 component:CharacterCount,
path:"/CharacterCount"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
TextComparison:{
 component:TextComparison,
path:"/TextComparison"},
RandomNumberGenerator:{
 component:RandomNumberGenerator,
path:"/RandomNumberGenerator"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
InvertColour:{
 component:InvertColour,
path:"/InvertColour"},
GoogleNewsIntegration:{
 component:GoogleNewsIntegration,
path:"/GoogleNewsIntegration"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
BeeFreeIntegration:{
 component:BeeFreeIntegration,
path:"/BeeFreeIntegration"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
WorldClock:{
 component:WorldClock,
path:"/WorldClock"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
ThemeBlock:{
 component:ThemeBlock,
path:"/ThemeBlock"},
GoogleContactsImport:{
 component:GoogleContactsImport,
path:"/GoogleContactsImport"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cobrowsing:{
 component:Cobrowsing,
path:"/Cobrowsing"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
WhiteboardCollaboration:{
 component:WhiteboardCollaboration,
path:"/WhiteboardCollaboration"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;