import React from "react";
import GoogleLoginButtonView from "./GoogleLoginButtonView";
import { googleLogout, useGoogleLogin, CodeResponse, TokenResponse } from "@react-oauth/google";

export const configJSON = require("./config");

interface Props {
  isSingedIn: boolean;
  onSuccess: (loginResponse: TokenResponse) => void;
  onError?: (loginResponse: Pick<CodeResponse, "error" | "error_description" | "error_uri">) => void;
  onLogout?: () => void;
}

export interface ViewProps {
  testID: string;
  onClickHandler: () => void,
  buttonText: string;
}

const GoogleLoginButton: React.FC<Props> = (props) => {
  const googleSignIn = useGoogleLogin({
    onSuccess: props.onSuccess,
    onError: props.onError,
    scope: configJSON.contactScope,
  });

  const googleSignOut = () => {
    googleLogout();
    props.onLogout && props.onLogout();
  }

  const onClickHandler = () => {
    if(props.isSingedIn) {
      googleSignOut();
    } else {
      googleSignIn();
    }
  }

  const viewProps: ViewProps = {
    testID: "googleLoginButtonView",
    onClickHandler,
    buttonText: props.isSingedIn ? configJSON.signOut : configJSON.importGoogleContact
  }

  return (
    <GoogleLoginButtonView {...viewProps} />
  )
}

export default GoogleLoginButton;
