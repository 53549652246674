import React from "react";

// Customizable Area Start
import { StyleSheet, Image } from "react-native";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { imgCompass } from "./assets";

import { Container, Button, Box } from "@mui/material";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CompassController, { Props, configJSON } from "./CompassController.web";

export default class Compass extends CompassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box
            data-test-id="btnStartCompass"
            onClick={() => this.startCompass()}
            component="button"
            sx={webStyle.buttonStyle}
          >
            <Button color={"primary"}>Start Compass</Button>
          </Box>
          <Image
            style={[
              styles.image,
              { transform: [{ rotate: `${360 - this.state.heading}deg` }] },
            ]}
            resizeMode="contain"
            source={imgCompass}
          />
          {/* </Container> */}
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const styles = StyleSheet.create({
  image: {
    marginTop: "20px",
    marginBottom: "10px",
    width: "90%",
    flex: 1,
    alignSelf: "center",
  },
});
// Customizable Area End
