import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Paper,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(98, 0, 238)",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import WordLookupController, {
  Props,
  configJSON,
} from "./WordLookupController.web";

export default class WordLookup extends WordLookupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6" style={webStyle.title}>
                {configJSON.pageTitle}
              </Typography>
              <Box style={webStyle.searchWrappper} maxWidth={"xs"}>
                <TextField
                  data-test-id="txtInput"
                  placeholder={configJSON.inputFieldPlaceholder}
                  fullWidth
                  value={this.state.word}
                  variant="outlined"
                  onChange={this.onChangeHandler}
                  style={webStyle.wordTextField}
                />
                <Button
                  data-test-id="btnSearch"
                  onClick={this.getDefinitionsData}
                  variant="contained"
                  style={webStyle.searchBtn}
                  color="primary"
                >
                  {configJSON.searchBtn}
                </Button>
              </Box>
              {this.state.definition && (
                <Box style={webStyle.resultWrapper}>
                  <Box style={webStyle.wordWrapper}>
                    <Typography variant="h4" data-test-id="wordText">
                      {this.state.definition.word}
                    </Typography>
                    {this.state.audio && (
                      <>
                        <Button
                          style={webStyle.speakButton}
                          onClick={this.playAudio}
                          data-test-id="btnPlay"
                        >
                          <VolumeUpIcon color="primary" />
                        </Button>
                        <audio ref={this.audioRef} src={this.state.audio} />
                      </>
                    )}
                  </Box>
                  {this.state.definition.meanings?.map((meaning, index) => {
                    return (
                      <Box
                        key={`meaning-${index}`}
                        data-test-id="meaningContainer"
                        style={webStyle.meaning}
                      >
                        <Typography variant="h5" style={webStyle.partOfSpeech}>
                          {meaning.partOfSpeech}
                        </Typography>
                        {meaning.definitions?.map((definition, dIndex) => {
                          return (
                            <Paper
                              key={`definition-${index}-${dIndex}`}
                              style={webStyle.definition}
                            >
                              <Typography
                                variant="h6"
                                style={webStyle.definitionTitle}
                              >
                                {dIndex + 1}. {definition.definition}
                              </Typography>
                              {definition.example && (
                                <Paper
                                  variant={"outlined"}
                                  style={webStyle.example}
                                >
                                  <Typography
                                    variant="h6"
                                    style={webStyle.exampleTitle}
                                  >
                                    {definition.example}
                                  </Typography>
                                </Paper>
                              )}
                            </Paper>
                          );
                        })}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  title: {
    marginTop: 50,
  },
  searchWrappper: {
    display: "flex",
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
  },
  wordTextField: {
    marginRight: 10,
  },
  searchBtn: {
    maxHeight: 56,
  },
  resultWrapper: {
    width: "100%",
    paddingTop: 20,
  },
  wordWrapper: {
    display: "flex",
  },
  speakButton: {
    marginLeft: 10,
  },
  meaning: {
    marginTop: 30,
  },
  partOfSpeech: {
    fontSize: 20,
    fontWeight: 700,
  },
  definition: {
    backgroundColor: "#fafafa",
    marginLeft: 30,
    marginTop: 20,
    padding: 10,
    borderRadius: 5,
  },
  definitionTitle: {
    fontSize: 16,
  },
  example: {
    backgroundColor: "#fff",
    marginLeft: 30,
    marginTop: 10,
    padding: 10,
  },
  exampleTitle: {
    fontSize: 18,
  },
};
// Customizable Area End
