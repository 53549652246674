import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End

import TextComparisonController, {
  Props,
  configJSON,
} from "./TextComparisonController";

export default class TextComparison extends TextComparisonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box sx={webStyle.mainWrapper}>
              <TextField
                data-test-id="txtLeft"
                value={this.state.firstString}
                onChange={this.handleChangeFirstString}
                multiline={true}
                minRows={5}
                fullWidth
                variant="outlined"
                style={webStyle.inputStyle}
              />
              <TextField
                data-test-id="txtRight"
                value={this.state.secondString}
                onChange={this.handleChangeSecondString}
                multiline={true}
                minRows={5}
                fullWidth
                variant="outlined"
                style={webStyle.inputStyle}
              />

              <Typography variant="h6" color="inherit">
                <pre data-test-id="comparedText">
                  {this.state.result.map((item, index) => {
                    const backgroundColor = item.added
                      ? "#90EE90"
                      : item.removed
                      ? "#FFCCCB"
                      : "#FFF";
                    return (
                      <span key={index} style={{ backgroundColor }}>
                        {item.value}
                      </span>
                    );
                  })}
                </pre>
              </Typography>

              <Typography variant="h6" data-test-id="addedStringCount">
                {configJSON.addedLabel}
                {this.state.added}
              </Typography>
              <Typography variant="h6">
                {configJSON.removedLabel}
                {this.state.removed}
              </Typography>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
  },
  inputStyle: {
    marginTop: 50,
  },
};
// Customizable Area End
