import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BeeFreeIntegrationController, {
  Props,
  configJSON,
} from "./BeeFreeIntegrationController";

export default class BeeFreeIntegration extends BeeFreeIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div
            data-test-id="bee-plugin-container"
            id="bee-plugin-container"
            style={webStyle.beeContainer}
            className="containerClassName"
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  beeContainer: {
    flex: 1,
  },
};
// Customizable Area End
