import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { SelectChangeEvent } from "@mui/material";
import { io, Socket } from "socket.io-client";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface IPath {
  path: {
    color: string;
    data: Array<string>;
    id: number;
    width: number;
  };
  size: { width: number; height: number };
  textField: string;
  fontSize: string;
  reactDetails: string | Array<string> | object | undefined;
  line: string;
  arrow: Array<string>;
  circle: string;
}

enum shapeType {
  reactangle = configJSON.shapeTypeReactangle,
  line = configJSON.shapeTypeLine,
  arrow = configJSON.shapeTypeArrow,
  circle = configJSON.shapeTypeCircle,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  paths: Array<IPath>;
  isDrawing: boolean;
  pathData: Array<string>;
  isDraw: boolean;
  isText: boolean;
  text: string;
  textPosition: {
    offsetX: number;
    offsetY: number;
  };
  shapePosition: {
    startX: number;
    startY: number;
  };
  shapeObject: string | Array<string> | object | undefined;
  fontSize: string;
  color: string;
  isShape: boolean;
  lineWidth: number;
  socketConnect: boolean;
  scaleX: number;
  scaleY: number;
  pointX: number;
  pointY: number;
  selectedShape: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WhiteboardCollaborationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  canvas!: React.RefObject<HTMLCanvasElement>;
  textInput!: React.RefObject<HTMLInputElement>;
  socket!: Socket;
  defaultScaleX!: number;
  defaultScaleY!: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      paths: [],
      isDrawing: false,
      pathData: [],
      isDraw: true,
      isText: false,
      text: "",
      textPosition: { offsetX: 0, offsetY: 0 },
      shapePosition: { startX: 0, startY: 0 },
      fontSize: "16",
      color: "#000000FF",
      isShape: false,
      shapeObject: undefined,
      lineWidth: 1,
      socketConnect: false,
      scaleX: 1,
      pointX: 0,
      pointY: 0,
      scaleY: 1,
      selectedShape: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    // connect socket
    this.setSocketConnection();
    const canvas = this.canvas?.current;
    if (canvas) {
      canvas.width = window.innerWidth * configJSON.canvasScale;
      canvas.height = window.matchMedia("(max-width: 768px)")?.matches
        ? (window.innerWidth / configJSON.canvasScale) * configJSON.canvasScale
        : window.innerHeight * configJSON.canvasScale;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${
        window.matchMedia("(max-width: 768px)")?.matches
          ? window.innerWidth / configJSON.canvasScale
          : window.innerHeight
      }px`;
      const context = canvas.getContext("2d");
      if (context) {
        context?.scale(configJSON.canvasScale, configJSON.canvasScale);
        context.lineCap = configJSON.canvasColor;
        context.strokeStyle = configJSON.canvaslineCap;
      }
    }
    // Increase scale for mobile view
    if (window.matchMedia("(max-width: 768px)")?.matches) {
      this.defaultScaleX = configJSON.mobileScaleX;
      this.defaultScaleY = configJSON.mobileScaleY;
    }

    // check data of socket
    this.socket.on("socket-data", (data: Array<IPath>) => {
      this.setState({ paths: data });
    });
  }

  componentDidUpdate = (prevProps: Props, prevState: S) => {
    if (prevState.paths !== this.state.paths) {
      this.onDrawCanvas();
    }
  };

  // Draw diagram on Canvas
  setSocketConnection = () => {
    this.socket = io(configJSON.socketPath, {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: Infinity,
      transports: ["websocket"],
    });

    this.socket.on("connect", () => {
      this.setState({ socketConnect: true });
    });

    this.socket.on("connect_error", () => {
      this.setState({ socketConnect: false });
    });

    this.socket.on("disconnect", () => {
      this.setState({ socketConnect: false });
    });
  };

  onDrawCanvas = () => {
    const { current: canvas } = this.canvas;
    const ctx = canvas?.getContext("2d");
    ctx?.clearRect(0, 0, canvas?.width as number, canvas?.height as number);
    if (this.state.paths?.length === 0) {
      return;
    }
    this.state.paths?.map((pathData: IPath) => {
      if (!pathData) {
        return;
      }
      const {
        path,
        size,
        arrow,
        circle,
        fontSize,
        reactDetails,
        line,
        textField,
      } = pathData;
      const { data, color, width } = path;
      // rerturn will empty
      if (path?.data?.length === 0) {
        return;
      }
      // text
      if (textField) {
        const pathXY = this.onResponseRatio(data[0], size);
        const fontRatio = this.onResponseFontSize(fontSize, size);
        if (ctx) {
          ctx.fillStyle = color;
          ctx.lineWidth = width;
          ctx.font = fontRatio + "px Arial, san-serif";
          ctx.fillText(textField, pathXY[0], pathXY[1]);
        }
      }
      // box
      if (
        reactDetails !== undefined &&
        Object.keys(reactDetails as object)?.length > 0
      ) {
        const pathXY = this.onResponseRatio(data[0], size);
        const sizeWH = this.onResponseSize(
          reactDetails as { width: number; height: number },
          size
        );
        if (ctx) {
          ctx.strokeStyle = color;
          ctx.lineWidth = width;
          ctx.strokeRect(pathXY[0], pathXY[1], sizeWH[0], sizeWH[1]);
        }
      }
      // line
      if (line) {
        const pathXY = this.onResponseRatio(data[0], size);
        const offSet = this.onResponseRatio(line, size);
        if (ctx) {
          ctx.beginPath();
          ctx.strokeStyle = color;
          ctx.lineWidth = width;
          ctx.moveTo(pathXY[0], pathXY[1]);
          ctx.lineTo(offSet[0], offSet[1]);
          ctx.stroke();
        }
      }
      // arrrow line
      if (arrow?.length > 0) {
        const pathXY = this.onResponseRatio(data[0], size);
        const offSet = this.onResponseRatio(arrow[0], size);
        const arrowUpSet = this.onResponseRatio(arrow[1], size);
        const arrowDownSet = this.onResponseRatio(arrow[2], size);
        if (ctx) {
          ctx.beginPath();
          ctx.moveTo(pathXY[0], pathXY[1]);
          ctx.strokeStyle = color;
          ctx.lineWidth = width;
          ctx.lineTo(offSet[0], offSet[1]);
          ctx.lineTo(arrowUpSet[0], arrowUpSet[1]);
          ctx.moveTo(offSet[0], offSet[1]);
          ctx.lineTo(arrowDownSet[0], arrowDownSet[1]);
          ctx.stroke();
        }
      }
      // circle
      if (circle) {
        const pathXY = this.onResponseRatio(data[0], size);
        const offSet = this.onResponseRatio(circle, size);
        const angle = Math.sqrt(
          Math.pow(pathXY[0] - offSet[0], 2) +
            Math.pow(pathXY[1] - offSet[1], 2)
        );
        if (ctx) {
          ctx.beginPath();
          ctx.strokeStyle = color;
          ctx.lineWidth = width;
          ctx.arc(pathXY[0], pathXY[1], angle, 0, 2 * Math.PI);
          ctx.stroke();
        }
      }
      // draw
      if (ctx) {
        ctx.beginPath();
        const pathXY = this.onResponseRatio(data[0], size);
        ctx.strokeStyle = color;
        ctx.lineWidth = width;
        ctx.moveTo(pathXY[0], pathXY[1]);
        data?.map((p: string) => {
          const pXY = this.onResponseRatio(p, size);
          ctx.lineTo(pXY[0], pXY[1]);
        });
        ctx.stroke();
      }
    });
  };

  //Calulate ratio as responsive
  onResponseRatio = (data: string, size: { width: number; height: number }) => {
    const xRatio = (this.canvas?.current?.width as number) / size.width;
    const yRatio = (this.canvas?.current?.height as number) / size.height;
    const pathXY = data.split(",");
    const responsiveX = Number(pathXY[0]) * xRatio;
    const responsiveY = Number(pathXY[1]) * yRatio;
    return [responsiveX, responsiveY];
  };

  // Calculate responsive font
  onResponseFontSize = (
    fontSize: string,
    size: { width: number; height: number }
  ) => {
    const xRatio = (this.canvas?.current?.width as number) / size.width;
    const responsiveSize = parseInt(fontSize) * xRatio;
    return responsiveSize;
  };

  //Calulate size as responsive
  onResponseSize = (
    data: { width: number; height: number },
    size: { width: number; height: number }
  ) => {
    const xRatio = ((this.canvas?.current?.width || 1) as number) / size.width;
    const yRatio =
      ((this.canvas?.current?.height || 1) as number) / size.height;
    const responsiveWidth = data.width * xRatio;
    const responsiveHeigth = data.height * yRatio;
    return [responsiveWidth, responsiveHeigth];
  };

  handleDrawStart = (xCord: number, yCord: number) => {
    const ctx = this.canvas?.current?.getContext("2d");
    ctx?.beginPath();
    ctx?.moveTo(xCord, yCord);
    let updatedState = { isDrawing: true };
    if (this.state.isShape) {
      updatedState = {
        ...updatedState,
        ...{ shapePosition: { startX: xCord, startY: yCord } },
      };
    }
    if (this.state.isText) {
      updatedState = {
        ...updatedState,
        ...{
          textPosition: { offsetX: xCord, offsetY: yCord },
          isDrawing: false,
          pathData: [...this.state.pathData, `${xCord},${yCord}`],
        },
      };
    }
    this.setState(updatedState);
  };

  // Handle will mouse down
  onMouseDown = ({
    nativeEvent,
  }: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const { offsetX, offsetY } = nativeEvent;
    this.handleDrawStart(offsetX, offsetY);
  };

  // handle touch start
  onTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = e?.nativeEvent?.targetTouches[0];
    const tranformOffset = this.getTransformedPoint(
      Number(clientX),
      Number(clientY)
    );
    this.handleDrawStart(tranformOffset.x, tranformOffset.y);
  };

  // Handle will mouse up
  onMouseUp = () => {
    const canvas = this.canvas.current;
    const context = canvas?.getContext("2d");
    context?.closePath();
    this.setState({ isDrawing: false });
    const {
      shapePosition,
      shapeObject,
      isDraw,
      color,
      isShape,
      pathData,
      lineWidth,
      text,
      fontSize,
      selectedShape,
    } = this.state;
    const pathObj = {
      path: {
        color: isDraw ? color : "#ffffff",
        data: isShape
          ? [...pathData, `${shapePosition?.startX},${shapePosition?.startY}`]
          : pathData,
        id: parseInt(Math.random().toFixed(8).split(".")[1]),
        width: lineWidth,
      },
      size: { width: canvas?.width || 0, height: canvas?.height || 0 },
      textField: text,
      fontSize: fontSize,
      reactDetails: {},
      line: "",
      arrow: [],
      circle: "",
    };
    if (isShape && selectedShape === shapeType.reactangle) {
      pathObj.reactDetails = shapeObject as object;
    }
    if (isShape && selectedShape === shapeType.line) {
      pathObj.line = shapeObject as string;
    }
    if (isShape && selectedShape === shapeType.arrow) {
      pathObj.arrow = shapeObject as [];
    }
    if (isShape && selectedShape === shapeType.circle) {
      pathObj.circle = shapeObject as string;
    }
    this.setState(
      {
        paths: [...this.state.paths, pathObj],
        pathData: [],
        text: "",
        isShape: false,
        shapePosition: { startX: 0, startY: 0 },
        shapeObject: undefined,
        selectedShape: 0,
      },
      () => {
        this.socket.emit("socket-data", this.state.paths);
      }
    );
  };

  // Handle on undo canvas
  undoCanvas = () => {
    const data = this.state.paths?.filter(
      (item: IPath, index: number) => index !== this.state.paths.length - 1
    );
    this.setState({ paths: data }, () => {
      this.socket.emit("socket-data", this.state.paths);
    });
  };

  handleDrawMove = (xCord: number, yCord: number) => {
    const ctx = this.canvas?.current?.getContext("2d") || null;
    const { isText, isDrawing, isDraw, lineWidth, color } = this.state;
    if (isText) {
      this.textInput?.current?.focus();
      return;
    }
    if (!isDrawing || isText) {
      return;
    }
    if (this.state.isShape) {
      this.onBoxHandle(xCord, yCord);
      return;
    }
    ctx?.lineTo(xCord, yCord);
    if (ctx !== null) {
      ctx.strokeStyle = isDraw ? color : "#ffffff";
      ctx.lineWidth = lineWidth;
    }
    ctx?.stroke();
    this.setState({ pathData: [...this.state.pathData, `${xCord},${yCord}`] });
  };

  // Handle on mouse move
  onMouseMove = ({
    nativeEvent,
  }: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const { offsetX, offsetY } = nativeEvent;
    this.handleDrawMove(offsetX, offsetY);
  };

  // Handle on mouse move
  onTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = e?.nativeEvent?.targetTouches[0];
    const tranformOffset = this.getTransformedPoint(
      Number(clientX),
      Number(clientY)
    );
    this.handleDrawMove(tranformOffset.x, tranformOffset.y);
  };

  // Handle on box
  onBoxHandle = (offsetX: number, offsetY: number) => {
    const ctx = this.canvas?.current?.getContext("2d") || null;
    const { shapePosition, lineWidth, isDraw, color, selectedShape } =
      this.state;
    // calculate the rectangle width/height based
    // on starting vs current mouse position
    const width = offsetX - shapePosition?.startX;
    const height = offsetY - shapePosition?.startY;
    this.onDrawCanvas();
    if (ctx !== null) {
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = isDraw ? color : "#ffffff";
    }
    if (selectedShape === shapeType.reactangle) {
      ctx?.strokeRect(
        shapePosition.startX,
        shapePosition.startY,
        width,
        height
      );
      this.setState({ shapeObject: { width, height } });
    }
    if (selectedShape === shapeType.line) {
      ctx?.beginPath();
      ctx?.moveTo(shapePosition.startX, shapePosition.startY);
      ctx?.lineTo(offsetX, offsetY);
      ctx?.stroke();
      this.setState({ shapeObject: `${offsetX},${offsetY}` });
    }
    if (selectedShape === shapeType.arrow) {
      const angle = Math.atan2(
        offsetY - shapePosition.startY,
        offsetX - shapePosition.startX
      );
      const anglePoint =
        (window.matchMedia("(max-width: 768px)")?.matches ? 5 : 15) * lineWidth;
      const arrowUpX = offsetX - anglePoint * Math.cos(angle - Math.PI / 6);
      const arrowUpY = offsetY - anglePoint * Math.sin(angle - Math.PI / 6);
      const arrowDownX = offsetX - anglePoint * Math.cos(angle + Math.PI / 6);
      const arrowDownY = offsetY - anglePoint * Math.sin(angle + Math.PI / 6);
      ctx?.beginPath();
      ctx?.moveTo(shapePosition.startX, shapePosition.startY);
      ctx?.lineTo(offsetX, offsetY);
      ctx?.lineTo(arrowUpX, arrowUpY);
      ctx?.moveTo(offsetX, offsetY);
      ctx?.lineTo(arrowDownX, arrowDownY);
      ctx?.stroke();
      this.setState({
        shapeObject: [
          `${offsetX},${offsetY}`,
          `${arrowUpX},${arrowUpY}`,
          `${arrowDownX},${arrowDownY}`,
        ],
      });
    }
    if (selectedShape === shapeType.circle) {
      const angle = Math.sqrt(
        Math.pow(shapePosition.startX - offsetX, 2) +
          Math.pow(shapePosition.startY - offsetY, 2)
      );
      ctx?.beginPath();
      ctx?.arc(
        shapePosition.startX,
        shapePosition.startY,
        angle,
        0,
        2 * Math.PI
      );
      ctx?.stroke();
      this.setState({ shapeObject: `${offsetX},${offsetY}` });
    }
  };

  // Handle on text input
  onBlurHandle = (e: { target: { value: string } }) => {
    const { color, fontSize, textPosition } = this.state;
    const ctx = this.canvas?.current?.getContext("2d") || null;
    if (ctx !== null) {
      ctx.fillStyle = color || "#ffffff";
      ctx.font = parseInt(fontSize) + "px Arial, san-serif";
    }
    ctx?.fillText(e?.target.value, textPosition.offsetX, textPosition.offsetY);
    this.setState({
      textPosition: { offsetX: 0, offsetY: 0 },
      isText: false,
    });
  };

  getTransformedPoint = (clientX: number, clientY: number) => {
    const canvas = this.canvas.current;
    const canvasOffset = canvas?.getBoundingClientRect();
    const topPos = canvasOffset?.left || 0;
    const leftPos = canvasOffset?.top || 0;
    const startX = Number(clientX - topPos);
    const startY = Number(clientY - leftPos);
    const offSetX = Math.trunc(startX / this.state.scaleX);
    const offSetY = Math.trunc(startY / this.state.scaleY);
    return { x: offSetX, y: offSetY };
  };

  calculateTop = () => {
    const top = configJSON.canvasTopPosition / this.state.scaleX;
    if (window.matchMedia("(max-width: 768px)")?.matches && top > 0) {
      return top;
    }
    return 0;
  };

  onShapeChange = (event: SelectChangeEvent<number>) => {
    this.setState({
      isShape: !this.state.isShape,
      isDraw: true,
      selectedShape: Number(event.target.value),
    });
  };

  setDraw = (value: boolean) => {
    this.setState({ isDraw: value });
  };

  setColor = (value: string) => {
    this.setState({ color: value });
  };

  setLineWidth = (number: number) => {
    this.setState({ lineWidth: number });
  };

  setFontSize = (value: string) => {
    this.setState({ fontSize: value });
  };

  setIsText = (value: boolean) => {
    this.setState({ isText: value });
  };

  setTextValue = (value: string) => {
    this.setState({ text: value });
  };

  setPoint = (pointX: number, pointY: number) => {
    this.setState({ pointX, pointY });
  };

  setScale = (scaleX: number, scaleY: number) => {
    this.setState({ scaleX, scaleY });
  };

  onDecreaseScaleButtonClick = () => {
    this.state.scaleX > configJSON.canvasZoomScale &&
      this.state.scaleY > configJSON.canvasZoomScale &&
      this.setScale(
        this.state.scaleX - configJSON.canvasZoomScale,
        this.state.scaleY - configJSON.canvasZoomScale
      );
  };

  onIncreaseScaleButtonClick = () => {
    this.setScale(
      this.state.scaleX + configJSON.canvasZoomScale,
      this.state.scaleY + configJSON.canvasZoomScale
    );
  };
  // Customizable Area End
}
