import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import BeePlugin, { beeTypes } from "@mailupinc/bee-plugin";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BeeFreeIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBeeFreeTemplateApiCallId: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    // Customizable Area Start
    if (this.isPlatformWeb()) {
      return this.getBeeFreeTemplate();
    }
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBeeFreeTemplateApiCallId != null &&
      this.getBeeFreeTemplateApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        const beeConfig: beeTypes.IBeeConfig = {
          uid: configJSON.CLIENT_UID,
          container: configJSON.containerName,
          language: configJSON.language,
          autosave: configJSON.autosave,
          onLoad: this.onBeeFreeEditorLoad,
          onError: this.onBeeFreeEditorError,
        };
        try {
          const beeEditor = new BeePlugin();
          await beeEditor.getToken(
            configJSON.CLIENT_ID,
            configJSON.CLIENT_SECRET
          );
          beeEditor.start(beeConfig, responseJson);
        } catch (error) {
          console.error(error);
        }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  getBeeFreeTemplate = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBeeFreeTemplateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TEMPLATE_URL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onBeeFreeEditorLoad = () => {};

  onBeeFreeEditorError = () => {};
  // Customizable Area End
}