import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";

interface Phonetics {
  text: string;
  audio: string;
  sourceUrl: string;
  license: {
    name: string;
    url: string;
  };
}

interface Meaning {
  partOfSpeech: string;
  definitions: {
    definition: string;
    synonyms: string[];
    antonyms: string[];
    example?: string;
  }[];
  synonyms: string[];
  antonyms: string[];
}

export interface Definition {
  word: string;
  phonetic: string;
  phonetics: Phonetics[];
  meanings: Meaning[];
  license: {
    name: string;
    url: string;
  };
  sourceUrls: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  word: string;
  definition: Definition | null;
  audio: string | null | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WordLookupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  audioRef: React.RefObject<HTMLAudioElement>;
  getDefinitionCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      word: "",
      definition: null,
      audio: null,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.audioRef = createRef<HTMLAudioElement>();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getDefinitionCallID) {
        if (responseJson && responseJson.length) {
          const definition: Definition = responseJson[0];
          const phonetic = definition.phonetics.find(
            (item) => item.audio && item.audio.length !== 0
          );
          this.setState({ definition: definition, audio: phonetic?.audio });
        } else {
          this.showAlert(configJSON.alertTitle, configJSON.tryAgainMsg);
          this.setState({ definition: null, audio: null });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getDefinitionsData = async () => {
    if (this.state.word.length === 0) {
      this.showAlert(configJSON.alertTitle, configJSON.emptyFieldMsg);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDefinitionCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apiUrl}${this.state.word}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  playAudio = () => {
    if(this.audioRef && this.audioRef.current) {
      this.audioRef.current.play();
    }
  };

  onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ word: event.target.value });
  };
  // Customizable Area End
}
