import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import PinInput from "react-pin-input";
import { Container, Button, Box } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {},
});
// Customizable Area End

import PasscodeLockController, {
  Props,
  configJSON,
} from "./PasscodeLockController";

export default class PasscodeLock extends PasscodeLockController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box sx={webStyle.mainWrapper}>
              <PinInput
                length={5}
                initialValue=""
                //secret
                ref={(p) => (this.pin = p)}
                type="numeric"
                inputMode="number"
                style={webStyle.style}
                inputStyle={webStyle.pinInputStyle}
                inputFocusStyle={webStyle.pinInputFocusStyle}
                onComplete={this.onComplete}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  style: {
    padding: "10px",
    alignSelf: "center",
  },
  pinInputStyle: {
    borderColor: "red",
  },
  pinInputFocusStyle: {
    borderColor: "blue",
  },
};
const styles = StyleSheet.create({});
// Customizable Area End
