import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Dispatch, SetStateAction } from "react";

// Customizable Area Start
const countriesDB = require("countries-db");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCountry: string;
  selectedTimezone: string;
  twentyFourHour: boolean;
  countryPickerOpen: boolean;
  countryValue: string;
  timezonePickerOpen: boolean;
  timezoneValue: string;
  formatPickerOpen: boolean;
  formatValue: string;
  countriesList: Array<{ label: string; value: string }> | Array<{}>;
  formatList: Array<{ label: string; value: string }> | Array<{}>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WorldClockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  timezoneList: Array<{ label: string; value: string }> | Array<{}> = [];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedCountry: "",
      selectedTimezone: "",
      twentyFourHour: false,
      countryPickerOpen: false,
      countryValue: "",
      timezonePickerOpen: false,
      timezoneValue: "",
      formatPickerOpen: false,
      formatValue: "",
      countriesList: [],
      formatList: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.setState({
      countriesList: this.getCountriesList(),
      formatList: this.getFormatOptionsMobile(),
    });
  }

  // Customizable Area Start
  getCountriesList = () => {
    const countries = Object.values(countriesDB.getAllCountries());
    return countries.map((country: any) => ({
      value: country.id,
      label: country.name,
    }));
  };

  setSelectedCountry = (id: string) => {
    this.setState(
      { selectedCountry: id },
      () => (this.timezoneList = this.getTimezonesList())
    );
  };

  getTimezonesList = () => {
    const countries: Array<any> = Object.values(countriesDB.getAllCountries());
    const timezones: Array<string> = countries.find(
      (country: any) => country.id === this.state.selectedCountry
    )?.timezones;

    let timezoneOptions: Array<{ label: string; value: string }> | Array<{}> =
      [];
    if (Boolean(timezones)) {
      timezoneOptions = timezones.map((item) => ({
        label: item,
        value: item,
      }));
    }

    return timezoneOptions;
  };

  setSelectedTimezone = (id: string) => {
    this.setState({ selectedTimezone: id });
  };

  getFormatOptions = () => {
    return [
      {
        label: "12 Hour",
        value: false,
      },
      {
        label: "24 Hour",
        value: true,
      },
    ];
  };

  getFormatOptionsMobile = () => {
    return this.getFormatOptions().map((item) => {
      return { label: item.label, value: item.value ? item.label : "" };
    });
  };

  setTwentyFourHour = (value: boolean) => {
    this.setState({ twentyFourHour: value });
  };

  getCurrentTimeInTimezone = (): string => {
    const { selectedTimezone } = this.state;

    if (!selectedTimezone) {
      return "";
    }

    const time = new Date().toLocaleTimeString("en-US", {
      timeZone: selectedTimezone,
      hour12: !this.state.twentyFourHour,
    });

    return time;
  };

  setCountryPickerOpen = (open: boolean) => {
    this.setState({
      countryPickerOpen: open,
    });
  };

  setCountryPickerValue = (callback: Dispatch<SetStateAction<string>>) => {
    this.setState({
      countryValue: callback(this.state.countryValue) as unknown as string,
    });
  };

  setTimezonePickerOpen = (open: boolean) => {
    this.setState({
      timezonePickerOpen: open,
    });
  };

  setTimezonePickerValue = (callback: Dispatch<SetStateAction<string>>) => {
    this.setState({
      timezoneValue: callback(this.state.timezoneValue) as unknown as string,
    });
  };

  setFormatPickerOpen = (open: boolean) => {
    this.setState({
      formatPickerOpen: open,
    });
  };

  setFormatPickerValue = (callback: Dispatch<SetStateAction<string>>) => {
    this.setState({
      formatValue: callback(this.state.formatValue) as unknown as string,
    });
  };
  // Customizable Area End
}
