import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { TokenResponse } from "@react-oauth/google";
// Customizable Area End

export const configJSON = require("./config");

export interface Contacts {
	// Customizable Area Start
	connections: {
		etag: string;
		names: {
			displayName: string;
			displayNameLastFirst: string;
			familyName: string;
			givenName: string;
			metadata: {
				primary: boolean;
				source: {
					id: string;
					type: string;
				};
			};
			unstructuredName: string;
		}[];
		phoneNumbers: {
			canonicalForm: string;
			formattedType: string;
			metadata: {
				primary: boolean;
				source: {
					id: string;
					type: string;
				};
			},
			type: string;
			value: string;
		}[];
		resourceName: string;
	}[];
	totalItems: number;
	totalPeople: number;
	// Customizable Area End
}

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	authDetails: {
		accessToken: string;
		idToken: string;
	} | null;
	contacts: Contacts | null;
	isSingedIn: boolean;
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}

export default class GoogleContactsImportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getContactCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authDetails: null,
      contacts: null,
      isSingedIn: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getContactCallId) {
        if (responseJson.connections) {
          this.setState({ contacts: responseJson });
        } else {
          this.setState({ authDetails: null });
          this.showAlert(
            configJSON.errorAlertLabel,
            configJSON.scopeAlertMessage
          );
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  getContacts = async (accessToken: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      Authorization: `${configJSON.tokenType} ${accessToken}`,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const apiKey = this.isPlatformiOS()
      ? configJSON.apiKeyiOS
      : configJSON.apiKeyWeb;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googlePeopleApi + apiKey
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  googleSignOut = async () => {
    this.setState({
      authDetails: null,
      isSingedIn: false,
      contacts: null,
    });
  };

  googleLoginSuccess = (loginResponse: TokenResponse) => {
    if(loginResponse.access_token) {
      this.setState({
        authDetails: {
          accessToken: loginResponse.access_token,
          idToken: loginResponse.token_type,
        },
        isSingedIn: true
      })
      this.getContacts(loginResponse.access_token)
    }
  }

  googleLoginError = async () => {
		this.showAlert(configJSON.errorAlertLabel, configJSON.signInAlertMessage);
	}
  // Customizable Area End
}
