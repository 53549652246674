Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.CLIENT_ID = "";
exports.CLIENT_SECRET = "";
exports.CLIENT_UID = "12345";
exports.getApiMethodType = "GET"
exports.TEMPLATE_URL = "https://rsrc.getbee.io/api/templates/m-bee";
exports.containerName = "bee-plugin-container";
exports.language = "en-US";
exports.autosave = true;
// Customizable Area End