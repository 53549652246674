Object.defineProperty(exports, "__esModule", {
	value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.apiMethodType = "GET";
exports.tokenType = "Bearer";

exports.apiKeyiOS = "___API_KEY_IOS___";
exports.apiKeyWeb = "___API_KEY_WEB___";
exports.clientIDiOS = "___CLIENT_ID_IOS___";
exports.clientIDWeb = "___CLIENT_ID_WEB___";
exports.contactScope = "https://www.googleapis.com/auth/contacts.readonly";
exports.googlePeopleApi = "https://people.googleapis.com/v1/people/me/connections?personFields=names%2Caddresses%2CcoverPhotos%2CphoneNumbers%2CemailAddresses&pageSize=1000&key=";

exports.contactList = "Contact Lists";
exports.totalContact = "Total Contact :";
exports.contactName = "Contact Name";
exports.contactNumber = "Contact Number";
exports.errorAlertLabel = "Error";
exports.signOut = "Sign Out";
exports.hashTag = "#";

exports.importGoogleContact = "Import Google Contact";
exports.scopeAlertMessage = "Please allow to access google contact! & Try again.";
exports.configurationAlertMessage = "Please configure google contact api details! & Try again.";
exports.signInAlertMessage = "Please sign in to google account! & Try again.";
exports.signOutAlertMessage = "Please try again to sign out! & Try again.";
// Customizable Area End
