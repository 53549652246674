import React from "react";
// Customizable Area Start
import { Container, Box, Typography, Switch } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import InvertColourController, {
  Props,
  configJSON,
} from "./InvertColourController";

export default class InvertColour extends InvertColourController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area End

  render() {
    let theme = createTheme({
      typography: {
        allVariants: {
          color: this.colors("text"),
        },
        h6: {
          fontWeight: 500,
        },
        subtitle1: {
          margin: "20px 0px",
        },
      },
    });
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box
              sx={webStyle.mainWrapper}
              style={{ backgroundColor: this.colors("background") }}
            >
              <Typography>{configJSON.labelTitleText}</Typography>
              <Switch
                data-test-id="btnInvertColors"
                checked={this.state.themeMode}
                onChange={() =>
                  this.setState({ themeMode: !this.state.themeMode })
                }
              />
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",

    height: "100vh",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
