import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End

import GoogleNewsIntegrationController, {
  Props,
  configJSON,
} from "./GoogleNewsIntegrationController";

export default class GoogleNewsIntegration extends GoogleNewsIntegrationController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"md"}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h4">{configJSON.googleNews}</Typography>
              <Box style={webStyle.searchContainer}>
                <TextField
                  data-test-id={"txtInput"}
                  value={this.state.searchKey}
                  onChange={(e: any) =>
                    this.setState({ searchKey: e.target.value })
                  }
                  variant="outlined"
                  label={configJSON.searchPlaceholder}
                  style={{ color: "#000" }}
                />
                <Button
                  data-test-id={"btnSearch"}
                  color="primary"
                  variant="contained"
                  onClick={() => this.getNewsByQuery(this.state.searchKey)}
                  style={webStyle.searchButton}
                >
                  {configJSON.searchButton}
                </Button>
              </Box>

              {this.state.data.length === 0 && (
                <Typography variant="h6">
                  {this.state.loading
                    ? configJSON.loading
                    : configJSON.dataNotFound}
                </Typography>
              )}
              {this.state.data.map((item: any, index: number) => {
                return (
                  <Box
                    key={index}
                    style={webStyle.newsContainer}
                    width={"100%"}
                    onClick={() => window.open(item.url, "_blank")}
                  >
                    <img src={item.urlToImage} style={webStyle.newsImg} />
                    <Box style={webStyle.newsContent}>
                      <Typography variant="h6" style={webStyle.newsDate}>
                        {item.publishedAt} {item?.author && " | " + item.author}
                      </Typography>
                      <Typography variant="h6" style={webStyle.title}>
                        {item.title}
                      </Typography>
                      <Typography component="p" style={webStyle.title}>
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const theme = createTheme({
  palette: {},
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
    paddingTop: "20px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  newsContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    border: "1px solid #ccc",
    padding: 20,
    borderRadius: 4,
    marginBottom: 20,
    cursor: "pointer",
  },
  newsImg: {
    height: 150,
    width: 150,
  },
  newsContent: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  newsDate: {
    fontSize: 14,
    color: "#696969",
    lineHeight: 1,
  },
  title: {
    lineHeight: 1.3,
    marginTop: 10,
  },
  searchContainer: {
    padding: 30,
  },
  searchButton: {
    padding: 15,
    borderRadius: 4,
    marginLeft: 10,
  },
};
// Customizable Area End
