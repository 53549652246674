import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Slider,
} from "@mui/material";
// Customizable Area End

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  BorderColor as BorderColorIcon,
  Delete as DeleteIcon,
  PanoramaFishEye as PanoramaFishEyeIcon,
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  Remove as RemoveIcon,
  ArrowForward as ArrowForwardIcon,
  PhonelinkErase as PhonelinkEraseIcon,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import WhiteboardCollaborationController, {
  Props,
  configJSON,
} from "./WhiteboardCollaborationController";

export default class WhiteboardCollaboration extends WhiteboardCollaborationController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.canvas = React.createRef();
    this.textInput = React.createRef();
    this.defaultScaleX = configJSON.webScaleX;
    this.defaultScaleY = configJSON.webScaleY;
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box style={webStyle.mainContainer}>
            <Box sx={webStyle.mainWrapper}>
              <Alert severity={this.state.socketConnect ? "success" : "error"}>
                {this.state.socketConnect
                  ? configJSON.labelSocketConnect
                  : configJSON.labelSocketDisconnect}
              </Alert>
              <Box style={webStyle.container}>
                <Box style={webStyle.boxes}>
                  <Button
                    variant="contained"
                    style={
                      this.state.isDraw
                        ? webStyle.activebtn
                        : webStyle.deactiveBtn
                    }
                    onClick={() => this.setDraw(true)}
                    data-test-id={"button-border-color"}
                  >
                    <BorderColorIcon />
                  </Button>
                </Box>
                <Box style={webStyle.boxes}>
                  <Button
                    variant="contained"
                    style={
                      !this.state.isDraw
                        ? webStyle.activebtn
                        : webStyle.deactiveBtn
                    }
                    onClick={() => this.setDraw(false)}
                    data-test-id={"button-erase"}
                  >
                    <PhonelinkEraseIcon />
                  </Button>
                </Box>
                <Box style={webStyle.boxes}>
                  <Input
                    type="color"
                    value={this.state.color}
                    style={webStyle.colorConatiner}
                    onChange={(e: { target: { value: string } }) => {
                      this.setColor(e.target.value);
                    }}
                    data-test-id={"input-color"}
                  />
                </Box>
                <Box style={webStyle.silderBoxes}>
                  <Slider
                    defaultValue={1}
                    value={this.state.lineWidth}
                    step={1}
                    min={1}
                    max={10}
                    valueLabelDisplay="auto"
                    onChange={(
                      event: Event,
                      newValue: number | number[],
                      activeThumb: number
                    ) => this.setLineWidth(newValue as number)}
                    style={webStyle.silderColor}
                    data-test-id={"input-slider"}
                  />
                </Box>
                <Box style={webStyle.boxes}>
                  <Button
                    variant="contained"
                    onClick={this.undoCanvas}
                    data-test-id={"button-undo"}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
                <Box style={webStyle.boxes}>
                  <Select
                    labelId="select-tools"
                    id="select-tools"
                    onChange={this.onShapeChange}
                    value={this.state.selectedShape}
                    style={webStyle.shapeDropDrown}
                  >
                    <MenuItem style={webStyle.shapeHide} value={0}>
                      <CheckBoxOutlineBlankOutlinedIcon />
                    </MenuItem>
                    <MenuItem value={configJSON.shapeTypeReactangle}>
                      <CheckBoxOutlineBlankOutlinedIcon />
                    </MenuItem>
                    <MenuItem value={configJSON.shapeTypeLine}>
                      <RemoveIcon />
                    </MenuItem>
                    <MenuItem value={configJSON.shapeTypeArrow}>
                      <ArrowForwardIcon />
                    </MenuItem>
                    <MenuItem value={configJSON.shapeTypeCircle}>
                      <PanoramaFishEyeIcon />
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box style={webStyle.canvasContainer}>
                <canvas
                  ref={this.canvas}
                  onMouseDown={this.onMouseDown}
                  onTouchStart={this.onTouchStart}
                  onMouseUp={this.onMouseUp}
                  onTouchEnd={this.onMouseUp}
                  onMouseMove={this.onMouseMove}
                  onTouchMove={this.onTouchMove}
                  style={{
                    ...webStyle.canvasBox,
                    ...{
                      top: `${this.calculateTop()}%`,
                      transform: `scale(${this.state.scaleX}, ${this.state.scaleY}) translate(${this.state.pointX}%, ${this.state.pointY}%)`,
                    },
                  }}
                  data-test-id={"canvas"}
                />
              </Box>
            </Box>
            <Box style={webStyle.toolBarContainer}>
              <Box style={webStyle.toolBar}>
                <Typography
                  style={
                    this.state.isText
                      ? webStyle.textBtnActive
                      : webStyle.textBtn
                  }
                  onClick={() => {
                    this.setIsText(!this.state.isText);
                    this.setDraw(true);
                  }}
                  data-test-id={"input-text-draw"}
                >
                  A
                </Typography>
                <TextField
                  type="number"
                  style={webStyle.inputText}
                  value={this.state.fontSize}
                  onChange={(e) => this.setFontSize(e.target.value)}
                  inputProps={{ style: webStyle.inputContainer }}
                  data-test-id={"input-font-size"}
                />
                <Input
                  ref={this.textInput}
                  type="text"
                  id="textField"
                  onBlur={this.onBlurHandle}
                  value={this.state.text}
                  onChange={(e: { target: { value: string } }) => {
                    this.setTextValue(e.target.value);
                  }}
                  style={{
                    ...webStyle.textBox,
                    ...{
                      left: this.state.textPosition.offsetX
                        ? this.state.textPosition?.offsetX * this.state.scaleX +
                          this.state.pointX *
                            this.defaultScaleX *
                            this.state.scaleX
                        : "-100%",
                      top: this.state.textPosition.offsetY
                        ? this.state.textPosition?.offsetY * this.state.scaleX +
                          this.state.pointY *
                            this.defaultScaleY *
                            this.state.scaleX +
                          60
                        : "-100%",
                      color: this.state.color,
                      fontSize: this.state.fontSize,
                      display: this.state.textPosition ? "block" : "none",
                    },
                  }}
                  data-test-id="input-text-value"
                />
              </Box>
              {/* scroll container */}
              <Box style={webStyle.scrollContainer}>
                <Box style={webStyle.scrollConatiner}>
                  <Button
                    style={webStyle.buttonArrow}
                    onClick={() =>
                      this.setPoint(
                        this.state.pointX,
                        this.state.pointY + configJSON.canvasZoom
                      )
                    }
                    data-test-id={"button-set-y-index"}
                  >
                    <div style={webStyle.buttonArrowUp} />
                  </Button>
                </Box>
                <Box style={webStyle.dFlex}>
                  <Button
                    style={webStyle.buttonArrow}
                    onClick={() => {
                      this.setPoint(
                        this.state.pointX + configJSON.canvasZoom,
                        this.state.pointY
                      );
                    }}
                    data-test-id={"button-set-x-index"}
                  >
                    <div style={webStyle.buttonArrowLeft} />
                  </Button>
                  <Button
                    style={webStyle.buttonArrowCenter}
                    onClick={() => {
                      this.setScale(
                        configJSON.canvasDefaultScale,
                        configJSON.canvasDefaultScale
                      );
                      this.setPoint(
                        configJSON.canvasPoint,
                        configJSON.canvasPoint
                      );
                    }}
                    data-test-id={"button-set-scale"}
                  />
                  <Button
                    style={webStyle.buttonArrow}
                    onClick={() =>
                      this.setPoint(
                        this.state.pointX - configJSON.canvasZoom,
                        this.state.pointY
                      )
                    }
                    data-test-id={"button-decrease-x-point"}
                  >
                    <div style={webStyle.buttonArrowRight} />
                  </Button>
                </Box>
                <Box style={webStyle.scrollConatiner}>
                  <Button
                    style={webStyle.buttonArrow}
                    onClick={() =>
                      this.setPoint(
                        this.state.pointX,
                        this.state.pointY - configJSON.canvasZoom
                      )
                    }
                    data-test-id={"button-decrease-y-point"}
                  >
                    <div style={webStyle.buttonArrowDown} />
                  </Button>
                </Box>
              </Box>
              {/* zoom */}
              <Box style={webStyle.zoomContainer}>
                <Box>
                  <Button
                    style={webStyle.buttonArrowCenter}
                    onClick={() => this.onIncreaseScaleButtonClick()}
                    data-test-id={"button-increase-both-scale"}
                  >
                    {"+"}
                  </Button>
                </Box>
                <Box>
                  <Button
                    style={webStyle.buttonArrowCenter}
                    onClick={() => this.onDecreaseScaleButtonClick()}
                    data-test-id={"button-decrease-both-scale"}
                  >
                    {"-"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    width: "100%",
    height: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    marginTop: 10,
    width: "100%",
    height: "100%",
  },
  toolBarContainer: {
    position: "fixed" as "fixed",
    bottom: 5,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toolBar: {
    backgroundColor: "#6200ee",
    // border: '2px solid #000',
    borderRadius: 10,
    padding: 15,
    display: "flex",
    flexDirection: "row" as "row",
  },
  activebtn: {
    color: "#fff",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  },
  deactiveBtn: {
    backgroundColor: "#e0e0e0",
    color: "#000",
  },
  textBtnActive: {
    backgroundColor: "#000",
    color: "#fff",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 18,
    borderRadius: 5,
    cursor: "pointer",
  },
  textBtn: {
    backgroundColor: "#fff",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 18,
    borderRadius: 5,
    cursor: "pointer",
  },
  inputText: {
    width: 40,
    height: 32,
    marginLeft: 10,
    color: "#fff",
    backgroundColor: "#fff",
    textAlign: "center" as "center",
    borderRadius: 5,
  },
  inputContainer: {
    textAlign: "center" as "center",
  },
  container: {
    display: "flex",
    marginTop: 10,
  },
  boxes: {
    padding: 2,
  },
  shapeDropDrown: {
    background: "rgb(224, 224, 224)",
    padding: "3px 4px",
    borderRadius: "9px",
  },
  silderBoxes: {
    width: 60,
    padding: "0 10",
  },
  canvasContainer: {
    overflow: "hidden",
    position: "relative" as "relative",
    backgroundColor: "#ccc",
    height: "100%",
    width: "100%",
  },
  scrollContainer: {
    position: "absolute" as "absolute",
    left: 10,
    bottom: 10,
  },
  zoomContainer: {
    position: "absolute" as "absolute",
    right: 10,
    bottom: 10,
  },
  buttonArrow: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "8px",
    border: "1px solid",
    minWidth: "auto",
  },
  buttonArrowCenter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "8px",
    border: "1px solid",
    minWidth: "28px",
  },
  buttonArrowUp: {
    /* Edges */
    borderLeft: "1px solid rgba(0, 0, 0, 0.3)",
    borderTop: "1px solid rgba(0, 0, 0, 0.3)",
    transform: "translateY(25%) rotate(45deg)",
    backgroundColor: "transparent",
    height: "12px",
    width: "12px",
  },
  buttonArrowDown: {
    /* Edges */
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    borderRight: "1px solid rgba(0, 0, 0, 0.3)",
    transform: "translateY(-25%) rotate(45deg)",
    backgroundColor: "transparent",
    height: "12px",
    width: "12px",
  },
  buttonArrowRight: {
    /* Edges */
    borderRight: "1px solid rgba(0, 0, 0, 0.3)",
    borderTop: "1px solid rgba(0, 0, 0, 0.3)",
    transform: "translateX(25%) rotate(45deg)",
    backgroundColor: "transparent",
    height: "12px",
    width: "12px",
  },
  buttonArrowLeft: {
    /* Edges */
    borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.3)",
    transform: "translateX(25%) rotate(45deg)",
    backgroundColor: "transparent",
    height: "12px",
    width: "12px",
  },
  silderColor: {
    color: "#000000",
  },
  shapeHide: {
    display: "none",
  },
  dFlex: {
    display: "flex",
  },
  scrollConatiner: {
    marginLeft: "32%",
  },
  colorConatiner: {
    width: 50,
  },
  canvasBox: {
    background: "#ffff",
    transformOrigin: "left top",
    position: "absolute" as "absolute",
  },
  textBox: {
    position: "fixed" as "fixed",
    border: "2px dashed #ccc",
    outline: "none",
  },
};
// Customizable Area End
