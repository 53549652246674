import React from "react";
import { ViewProps } from "./GoogleLoginButton";
import { Button } from "@mui/material";
import { googleIcon } from "./assets";

const GoogleLoginButtonView: React.FC<ViewProps> = ({
  onClickHandler,
  buttonText,
}) => {
  return (
    <Button
      data-test-id="googleSignInBtn"
      style={webStyle.googleSignInButton}
      onClick={onClickHandler}
    >
      <img src={googleIcon} style={webStyle.googleIcon} />
      {buttonText}
    </Button>
  );
};

const webStyle = {
  googleSignInButton: {
    backgroundColor: "#fff",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow:
      "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    borderRadius: 2,
    border: "1px solid transparent",
    fontSize: 14,
    fontWeight: 500,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  googleIcon: {
    height: 18,
    marginRight: 10,
  },
};

export default GoogleLoginButtonView;
